<template>
	<el-dialog title="上传扫描件" :close-on-click-modal="false" :visible.sync="visible"
		width="1000px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-row>
				<el-col :span="24">
					<el-form-item label="扫描件">
						<div class="addFileBox" v-for="(item,index) in fileList">
						    <div>
								<span style="display: inline-block; width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; vertical-align: top;">
									{{item.fileName}}
								</span>
								<i class="el-icon-delete" @click="delFile(index)"></i>
							</div>
						</div>
						<el-upload
						  class="addFileBox"
						  ref="uploadFile"
						  :action="action"
						  v-if="!fileList || fileList.length < 1"
						  :http-request="uploadFile">
						  <el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-folder-add" style="padding: 9px 0 !important;">添加文件</el-button>
						</el-upload>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
		</el-row>
	</el-dialog>

</template>

<script>
	import $common from "@/utils/common.js"
	export default {
		name: "ownerContract-add-or-update",
		data() {
			return {
				visible: false,
				inline: false,
				action: "",
				fileList:[],
				dataForm: {
					contractId:"",
					contractPath:""
				},
				dataRule: {
					contractPath: [
						{ required: true, message: "请上传扫描件", trigger: "blur" }
					]
				},
			}
		},
		methods: {
			init(id) {
				this.fileList=[];
				this.dataForm = {
					contractId:"",
					contractPath:""
				};
				this.dataForm.contractId = id?id:0;
				this.$nextTick(() => {
					this.visible = true;
				})
			},
			delFile(index){
				this.dataForm.contractPath = "";
				this.fileList = [];
			},
			uploadFile(param){
				const formData = new FormData();
				formData.append('file', param.file);
				this.$refs.uploadFile.clearFiles();
				this.$http.post(
					this.$store.state.httpUrl + "/file/upload/"+$common.getItem("workNo")+"/97/0", 
					formData, 
					{
					  headers: { 'Content-Type': 'multipart/form-data' }
					},
				).then(res => {
				  res = res.data
				  if (res.resultCode == 200){
					this.dataForm.contractPath = res.body.fileSavePath;
					this.fileList = [{
						fileName: res.body.fileName,
						filePath: res.body.fileSavePath,
						fileUri: res.body.fileUri
					}];
				  } else {
					this.$message.info('文件上传失败')
				  }
				})
			},
			dataFormSubmit() {
			  this.$refs["dataForm"].validate(valid => {
				if (valid) {
				  this.$http({
					url: this.$store.state.httpUrl + "/business/ownercontractinfo/queryFileUri",
					method: "post",
					data: this.$http.adornData(this.dataForm)
				  }).then(({ data }) => {
					if (data && data.resultCode === 200) {
					  this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
						onClose: () => {
						  this.visible = false;
						  this.$emit("refreshDataList");
						}
					  });
					} else {
					  this.$message.error(data.msg);
					}
				  });
				}
			  });
			}
		}
	}
</script>
<style lang="scss"  scoped>
	.selItemInput.descAreaLg{
		width: 718px !important;
	}
</style>
